import { useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { useEventListener } from 'usehooks-ts';
import { useLocation } from 'react-router-dom';

import Logo from 'src/assets/images/logo.svg';
import AgentCall from 'src/assets/icons/icon-agent-call.svg';
import CloseIcon from 'src/assets/icons/close-icon.svg';
import InfoIconMenu from 'src/assets/icons/icon-info-menu.svg';
import TermsIconMenu from 'src/assets/icons/icon-terms.svg';
import NavigationCarImg from 'src/assets/images/car-img-navigation.png';
import { BarsIcon } from 'src/assets/icons/bars.svg';
import LanguageSelector from 'src/components/language-selector';
import { useMultiLngRoutes } from 'src/hooks/use-multi-routes';
import { useTranslations } from 'src/utils/constants';
import iconCarGreen from 'src/assets/icons/icon-car-green.svg';

import image1001 from 'src/assets/icons/icon-home.svg';
import image1002 from 'src/assets/icons/icon-car.svg';
import image1003 from 'src/assets/icons/icon-setting.svg';
import image1004 from 'src/assets/icons/icon-faq.svg';
import image1005 from 'src/assets/icons/icon-contact.svg';
import image1006 from 'src/assets/icons/circle-check-primary.svg';

export const allImages = [
	image1001, // shorthand for image1001: image1001,
	image1002,
	image1003,
	image1004,
	image1005,
	image1006
];

function Header() {
	const location = useLocation();

	const translations = useTranslations();

	const showLanguageSelector = !['/de/blog', '/de/blog_details'].includes(location.pathname);

	const { getUrl } = useMultiLngRoutes();

	const [showMenu, setShowMenu] = useState(false);
	const [isFixed, setIsFixed] = useState(true);

	const links = [
		{
			href: getUrl('index'),
			label: 'Home',
			title: 'Home'
		},
		{
			href: getUrl('cars'),
			label: translations?.header.cars_on_subscription,
			title: translations?.header.cars_on_subscription
		},
		{
			href: getUrl('car_subscription'),
			label: translations?.header.this_is_how_it_works,
			title: 'Auto Abo günstig in der Schweiz'
		},
		{
			href: getUrl('faq'),
			label: translations?.header.menu_frequently_asked_questions,
			title: 'häufige fragen zum auto abo'
		}
	];

	const onScroll = () => {
		const scrollTop = window.scrollY;
		setIsFixed(scrollTop <= 50);
	};

	useEventListener('scroll', onScroll);

	return (
		<header
			className={cx(
				isFixed
					? 'absolute px-4 lg:pb-2 lg:pt-[20px] xl:shadow-none bg-white lg:bg-transparent'
					: 'fixed px-2.5 bg-white sm:px-0',
				'top-0 py-2.5 w-full flex justify-center items-center z-header transition duration-150 shadow-[0_4px_2px_rgba(0,0,0,0.05)] lg:shadow-[0_2px_20px_rgba(0,0,0,0.05)]'
			)}>
			<div className="flex items-center justify-between custom-container">
				<button className="block xl:hidden" onClick={() => setShowMenu(!showMenu)}>
					<BarsIcon />
				</button>
				<Link to={getUrl('index')} title="Startseite">
					<img
						className="my-2 h-35 sm:h-45 sm:my-2 lg:my-6"
						src={Logo}
						alt={translations?.common.simpcar_subscription}
						title={translations?.common.simpcar_subscription}
					/>
				</Link>
				<nav className="items-center justify-center flex-1 hidden xl:flex">
					<ul className="flex justify-between items-center gap-1 p-1 rounded-35 bg-white shadow-[0_0_1px_0_#23232387]">
						{links.map((item) => (
							<li key={item.href} className="text-lg flex items-center">
								<Link
									className={cx(
										'inline-block text-primary rounded-38 py-3 px-8 text-base font-medium leading-5 overflow-hidden text-ellipsis whitespace-nowrap',
										'hover:bg-primary hover:text-white',
										{ 'bg-primary text-white': item.href === location.pathname }
									)}
									to={item.href}
									title={item.title}>
									{item.label}
								</Link>
							</li>
						))}
					</ul>
				</nav>
				<nav
					className={cx(
						'fixed top-0 bg-white bg-gradient-to-b from-white from-30% via-white to-[#F5F5F5] to-30% xl:hidden w-full h-full transition-all duration-500 ease-out z-10 overflow-hidden',
						showMenu ? 'left-0' : 'left-[-100%]'
					)}>
					<div className="p-6 py-3">
						<Link to={getUrl('index')} title="Startseite" onClick={() => setShowMenu(!showMenu)}>
							<img
								className="my-2 h-35 sm:h-45 sm:my-2 lg:my-6"
								src={Logo}
								alt={translations?.common.simpcar_subscription}
								title={translations?.common.simpcar_subscription}
							/>
						</Link>
						<span
							className="absolute flex items-center justify-center rounded-full cursor-pointer bg-primary-100 right-6 lg:right-35 top-22 lg:top-35 size-35 text-primary"
							onClick={() => setShowMenu(!showMenu)}>
							<CloseIcon className="w-15" />
						</span>
					</div>
					<div className="p-6 mr-6 mobileMenu bg-primary rounded-e-15">
						<div className="mb-2 text-14 font-semibold text-white welcome-txt">
							{translations?.common.welcome_to_simpcar}
						</div>
						<ul className="flex flex-col">
							{links.map((item, index) => (
								<li key={item.href} className="py-0 text-xl font-bold">
									<Link
										className={cx('flex gap-4 items-center px-0 text-white leading-5 text-base', {
											'text-white': item.href === location.pathname
										})}
										to={item.href}
										title={item.title}
										onClick={() => setShowMenu(false)}>
										<img src={allImages[index]} alt="" className="size-6 mt-2" />
										<span
											className={cx('w-full pt-4 mb-3 block border-[#ffffff]', {
												'border-t': index > 0
											})}>
											{item.label}
										</span>
									</Link>
								</li>
							))}
						</ul>
					</div>
					<div className="flex flex-wrap gap-3 p-6 mr-6 rounded-e-15">
						<a
							className="flex items-center w-full gap-4 px-0 leading-5 text-primary text-base"
							href={getUrl('about_us')}
							title={translations?.footer.about_the_company}>
							<img className="size-6 -mt-2" src={InfoIconMenu} />
							<span className="font-bold w-full pb-2 border-b block border-primary">
								{translations?.header.about_simpcar}
							</span>
						</a>
						<a
							className="flex items-center w-full gap-4 px-0 leading-5 text-primary text-base"
							href={getUrl('terms_conditions')}
							title={translations?.header.menu_general_terms_services}>
							<img className="size-6 -mt-2" src={TermsIconMenu} />
							<span className="font-bold w-full pb-2 border-b block border-primary">
								{translations?.header.menu_general_terms_services}
							</span>
						</a>
						<a href={getUrl('privacy_policy')}>
							<span className="text-primary text-[12px] ml-9 font-semibold">
								{translations?.header.menu_privacy_policy}
							</span>
						</a>
					</div>
					<div className="absolute bottom-0 left-16 w-full mobile-car-img">
						<img className="w-full" src={NavigationCarImg} />
					</div>
				</nav>

				{showLanguageSelector && <LanguageSelector className="mr-2" />}
				<Link
					className={cx(
						'py-2.5 px-7 text-white bg-primary rounded-35 text-base transition duration-150 leading-5 hidden',
						'hover:bg-transparent hover:text-primary',
						'xl:inline-block'
					)}
					to={getUrl('contact')}>
					{translations?.common.contact}
				</Link>
				<Link
					className={cx('bg-white rounded-full transition duration-150 inline-block', 'xl:hidden')}
					to={getUrl('contact')}>
					<img src={AgentCall} className="w-26 lg:w-53" />
				</Link>
			</div>
			<div className="fixed bottom-0 left-0 w-full bg-primary text-white footer-fix-mobile-menu xl:hidden">
				<ul className="flex items-center justify-evenly text-center py-3">
					<li>
						<Link
							className="flex flex-wrap items-center w-full gap-2 p-3 leading-5 text-primary text-base"
							to={getUrl('index')}>
							<img className="size-6 mx-auto" src={image1001} />
							<span className="font-bold text-14 w-full block text-white">
								{translations?.header.home}
							</span>
						</Link>
					</li>
					<li className="w-5/12">
						<a
							className="flex flex-wrap items-center w-full gap-2 p-3 leading-5 text-primary bg-white rounded-7 text-base"
							href={getUrl('cars')}
							title={translations?.subscription.car_subscription_in_switzerland}>
							<img className="size-6 mx-auto" src={iconCarGreen} />
							<span className="font-bold text-14 w-full block text-primary">
								{translations?.header.search_car}
							</span>
						</a>
					</li>
					<li>
						<a
							className="flex flex-wrap items-center w-full gap-2 p-3 leading-5 text-primary text-base"
							href={getUrl('contact')}
							title={translations?.footer.simpcar_contact}>
							<img className="size-6 mx-auto" src={image1005} />
							<span className="font-bold text-14 w-full block text-white">
								{translations?.common.contact}
							</span>
						</a>
					</li>
				</ul>
			</div>
		</header>
	);
}

export default Header;
