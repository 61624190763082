import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { LanguageType } from './types';
import MultiRoutes from './configs/routes';
import Layout from './components/layout';

import { LoadingScreen } from './components/loading-screen';

const Home = lazy(() => import('src/pages/home'));
const Cars = lazy(() => import('src/pages/cars'));
const CarDetail = lazy(() => import('src/pages/car-detail'));
const CarSubscription = lazy(() => import('src/pages/car-subscription'));
const AboutUs = lazy(() => import('src/pages/about-us'));
const FAQ = lazy(() => import('src/pages/faq'));
const PrivacyPolicy = lazy(() => import('src/pages/privacy-policy'));
const TermsAndConditions = lazy(() => import('src/pages/terms-conditions'));
const Contact = lazy(() => import('src/pages/contact'));
const AutoSubscribe = lazy(() => import('src/pages/auto-subscribe'));
const Imprint = lazy(() => import('src/pages/imprint'));
const Blogs = lazy(() => import('src/pages/blogs'));
const BlogDetail = lazy(() => import('src/pages/blog-detail'));

import './App.css';

function App() {
	const { i18n } = useTranslation();

	const lng = i18n.language as LanguageType;

	return (
		<Router>
			<Suspense fallback={<LoadingScreen />}>
				<Routes>
					{['en', 'de', 'fr'].map((item) => {
						const lngItem = item as LanguageType;

						return (
							<Route key={lngItem} path={`/${lngItem}`} element={<Layout />}>
								<Route index element={<Home />} />
								<Route path={MultiRoutes['auto_subscribe'][lngItem]} element={<AutoSubscribe />} />
								<Route path={MultiRoutes['car_detail'][lngItem]} element={<CarDetail />} />
								<Route path={MultiRoutes['cars'][lngItem]} element={<Cars />} />
								<Route
									path={MultiRoutes['car_subscription'][lngItem]}
									element={<CarSubscription />}
								/>
								<Route path={MultiRoutes['about_us'][lngItem]} element={<AboutUs />} />
								<Route path={MultiRoutes['faq'][lngItem]} element={<FAQ />} />
								<Route path={MultiRoutes['privacy_policy'][lngItem]} element={<PrivacyPolicy />} />
								<Route
									path={MultiRoutes['terms_conditions'][lngItem]}
									element={<TermsAndConditions />}
								/>
								<Route path={MultiRoutes['imprint'][lngItem]} element={<Imprint />} />
								<Route path={MultiRoutes['contact'][lngItem]} element={<Contact />} />
								<Route path={MultiRoutes['blog'][lngItem]} element={<Blogs />} />
								<Route path={MultiRoutes['blog_details'][lngItem]} element={<BlogDetail />} />
							</Route>
						);
					})}
					<Route path="*" element={<Navigate to={`/${lng}`} />} />
				</Routes>
			</Suspense>
		</Router>
	);
}

export default App;
