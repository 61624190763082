import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

let lng = window.location.pathname.split('/')[1];

if (!['de', 'en', 'fr'].includes(lng)) {
	lng = 'de';
}

i18n.use(initReactI18next).init({
	lng,
	fallbackLng: 'en',
	interpolation: {
		escapeValue: false
	}
});

export default i18n;
