import axios, { AxiosRequestConfig } from 'axios';
import { useLocalStorage } from 'usehooks-ts';

import { API_URL } from './config';

const Axios = axios.create();

Axios.defaults.baseURL = API_URL;

Axios.defaults.headers.common['Content-Type'] = 'application/json';

// axiosClient.defaults.timeout = 2000; //All request will wait 2 seconds before timeout

// Axios.defaults.withCredentials = true;

// Axios.interceptors.request.use(
// 	function (request) {
// 		const [token] = useLocalStorage('token', '');
// 		// request.headers["Content-Type"] = "multipart/form-data";
// 		request.headers['Authorization'] = `Bearer ${token}`;
// 		return request;
// 	},
// 	null,
// 	{ synchronous: true }
// );

Axios.interceptors.response.use(
	function (response) {
		//Dispatch any action on success
		return response;
	},
	function (error) {
		const status = error.response.status;
		if (status === 401 || status === 400) {
			// Add Logic to
			// 1. Redirect to login page or
			// 2. Request refresh token
		}
		return Promise.reject(error);
	}
);

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
	const [url, config] = Array.isArray(args) ? args : [args];

	const res = await Axios.get(url, { ...config });

	return res.data;
};

export const endpoints = {
	car: {
		list: '/cars/list',
		details: '/cars/details',
		search: '/cars/multi-search',
		featured: '/cars/featured'
	},
	order: {
		create: 'orders/create'
	},
	blog: {
		search: '/blogs/search',
		details: '/blogs/details',
		url: '/blogs/details-url',
		suggested: '/blogs/suggested'
	},
	request: {
		create: 'requests/create'
	},
	constants: {
		get: '/constants'
	}
};

export default Axios;
