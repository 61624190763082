// ----------------------------------------------------------------------

import { BarLoader, ClipLoader } from 'react-spinners';

export default function LoadingScreen() {
	return (
		<div className="w-full h-screen flex justify-center items-center">
			<BarLoader
				color="#92bd44"
				width={250}
				loading={true}
				aria-label="Loading Spinner"
				data-testid="loader"
			/>
		</div>
	);
}
