import { useTranslation } from 'react-i18next';

import Logo from 'src/assets/images/logo.svg';
import Image1 from 'src/assets/images/simpcar_telefon.webp';
import Image2 from 'src/assets/images/simpcar_adresse.webp';
import Image3 from 'src/assets/images/simpcar_email.webp';
import GoogleImage from 'src/assets/icons/icon-google.svg';
import StarImage from 'src/assets/icons/icon-star.svg';
import { useMultiLngRoutes } from 'src/hooks/use-multi-routes';
import { useTranslations } from 'src/utils/constants';

function Footer() {
	const { i18n } = useTranslation();

	const lng = i18n.language;

	const translations = useTranslations();

	const { getUrl } = useMultiLngRoutes();

	return (
		<footer className="bg-[#F4F5F8]">
			<div className="pt-50 custom-container flex gap-30 flex-col flex-wrap lg:flex-nowrap md:flex-row">
				<div className="md:w-5/12">
					<div className="mb-25">
						<img className="w-40" src={Logo} alt="simpcar" title="simpcar" />
					</div>
					<p
						className="leading-6 text-15 max-w-[95%]"
						dangerouslySetInnerHTML={{ __html: translations?.footer.description }}></p>
					<div className="mt-25">
						<div className="mt-15">
							<div className="flex gap-15 items-center mb-15">
								<img
									className="w-30"
									src={Image1}
									title={translations?.footer.simpcar_number}
									alt={translations?.footer.simpcar_number}
								/>
								<a className="text-base text-[#707070]" href="tel:+41315582500">
									031 558 25 00
								</a>
							</div>
							<div className="flex gap-15 items-center mb-15">
								<img
									className="w-30"
									src={Image2}
									title={translations?.footer.simpcar_address}
									alt={translations?.footer.simpcar_address}
								/>
								<div className="text-[#707070]">
									Sägeweg 30
									<br />
									3283 Kallnach
								</div>
							</div>
							<div className="flex gap-15 items-center mb-15">
								<img
									className="w-30"
									src={Image3}
									title={translations?.footer.simpcar_email}
									alt={translations?.footer.simpcar_email}
								/>
								<a className="text-[#707070] text-base" href="mailto:info@simpcar.ch">
									info@simpcar.ch
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className="md:w-3/12">
					<p className="font-roboto font-bold text-base mb-2.5">
						{translations?.footer.the_car_subscription}
					</p>
					<div className="leading-loose">
						<a
							className="text-[#707070] text-sm"
							href={getUrl('car_subscription')}
							title={translations?.subscription.car_subscription_switzerland}>
							{translations?.header.this_is_how_it_works}
						</a>
					</div>
					<div className="leading-loose">
						<a
							className="text-[#707070] text-sm"
							href={getUrl('cars')}
							title={translations?.subscription.car_subscription_switzerland}>
							{translations?.footer.explore_cars}
						</a>
					</div>
					<div className="leading-loose">
						<a
							className="text-[#707070] text-sm"
							href={getUrl('faq')}
							title={translations?.footer.FAQ}>
							{translations?.footer.FAQ}
						</a>
					</div>
					<p className="font-roboto font-bold text-base mt-6 mb-2.5">simpcar</p>
					<div className="leading-loose">
						<a
							className="text-[#707070] text-sm"
							href={getUrl('about_us')}
							title={translations?.footer.about_the_company}>
							{translations?.footer.about_us}
						</a>
					</div>
					<div className="leading-loose">
						<a
							className="text-[#707070] text-sm"
							href={getUrl('contact')}
							title={translations?.footer.simpcar_contact}>
							{translations?.common.contact}
						</a>
					</div>
					{lng === 'de' && (
						<div className="leading-loose">
							<a
								className="text-[#707070] text-sm"
								href={getUrl('blog')}
								title={translations?.blogs.blog}>
								{translations?.blogs.blog}
							</a>
						</div>
					)}
				</div>
				<div className="md:w-4/12">
					<div className="flex gap-15 items-center">
						<img src={GoogleImage} alt="icon-google" title="Google" className="w-10" />
						<div className="flex justify-center flex-col">
							<div className="flex mb-2 gap-1">
								<img src={StarImage} alt="icon-star" title="Star" className="w-3" />
								<img src={StarImage} alt="icon-star" title="Star" className="w-3" />
								<img src={StarImage} alt="icon-star" title="Star" className="w-3" />
								<img src={StarImage} alt="icon-star" title="Star" className="w-3" />
								<img src={StarImage} alt="icon-star" title="Star" className="w-3" />
								<span className="ml-2 text-xs text-primary">
									{translations?.common['5_out_of_5_stars']}
								</span>
							</div>
							<div className="text-13">{translations?.common.from_53_reviews}</div>
						</div>
					</div>
				</div>
			</div>
			<div className="mt-35 bg-primary py-[20px]">
				<div className="custom-container">
					<div className="flex flex-wrap md:flex-nowrap justify-between items-center">
						<p className="text-15 text-white">
							<a
								className="text-white"
								href={getUrl('terms_conditions')}
								title={translations?.footer.general_terms}>
								{translations?.footer.terms_conditions}
							</a>{' '}
							–{' '}
							<a
								className="text-white"
								href={getUrl('privacy_policy')}
								title={translations?.footer.privacy_policy}>
								{translations?.footer.privacy_policy}
							</a>{' '}
							–{' '}
							<a
								className="text-white"
								href={getUrl('imprint')}
								title={translations?.footer.impressum}>
								{translations?.footer.impressum}
							</a>
						</p>
						<p className="text-15 text-white">
							© 2024 simpcar. {translations?.footer.all_rights_reserved}.
						</p>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
