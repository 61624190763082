import { LanguageType } from 'src/types';

const MultiRoutes: Record<string, Record<LanguageType, string>> = {
	about_us: {
		en: '/en/about-us',
		de: '/de/ueber-uns',
		fr: '/fr/à-propos-de-nous'
	},
	auto_subscribe: {
		en: '/en/subscribe/auto-subscribe/:id/:km/:term',
		de: '/de/abonnieren/autoabo/:id/:km/:term',
		fr: '/fr/abonnieren/autoabo/:id/:km/:term'
	},
	car_subscribe_confirm: {
		en: '/en/auto-subscription/confirmation',
		de: '/de/auto-abo/bestaetigung',
		fr: '/fr/abonnement/confirmation-automatique'
	},
	car_subscription: {
		en: '/en/car-subscription',
		de: '/de/auto-abo',
		fr: '/fr/abonnement-voiture'
	},
	cars: {
		en: '/en/cars',
		de: '/de/fahrzeuge',
		fr: '/fr/voitures'
	},
	car_detail: {
		en: '/en/cars/:id/:tag',
		de: '/de/fahrzeuge/:tag/:id',
		fr: '/fr/voitures/:id/:tag'
	},
	contact: {
		en: '/en/contact',
		de: '/de/kontakt',
		fr: '/fr/contact'
	},
	faq: {
		en: '/en/faq',
		de: '/de/faq',
		fr: '/fr/faq'
	},
	imprint: {
		en: '/en/imprint',
		de: '/de/impressum',
		fr: '/fr/imprimer'
	},
	index: {
		en: '/en',
		de: '/de',
		fr: '/fr'
	},
	privacy_policy: {
		en: '/en/privacy-policy',
		de: '/de/datenschutzbestimmungen',
		fr: '/fr/politique-de-confidentialite'
	},
	terms_conditions: {
		en: '/en/terms-and-conditions',
		de: '/de/agb',
		fr: '/fr/termes-et-conditions'
	},
	blog: {
		en: '',
		de: '/de/blog',
		fr: ''
	},
	blog_details: {
		en: '',
		de: '/de/blog/:url',
		fr: ''
	}
};

export default MultiRoutes;
